import React, { useEffect, useState, useRef } from 'react'
import { MapContainer, TileLayer, Popup, useMapEvents, Marker, Circle, Polygon, Rectangle, ImageOverlay} from 'react-leaflet'
import GeoTIFF from 'geotiff';
import './styles.css'
import 'leaflet/dist/leaflet.css';
import parseGeoRaster from "georaster" 
import GeoRasterLayer from "georaster-layer-for-leaflet"
import PositionPopUp from '../PositionPopUp.jsx';


// const GeoRasterOverlay = () => {
//   const [geoRasterLayer, setGeoRasterLayer] = useState(null);

//   useEffect(() => {
//     const urlToGeotiffFile = "example_4326.tif";

//     fetch(urlToGeotiffFile)
//       .then(response => response.arrayBuffer())
//       .then(arrayBuffer => {
//         parseGeoRaster(arrayBuffer).then(georaster => {
//           console.log("georaster:", georaster);

//           const layer = new GeoRasterLayer({
//             georaster: georaster,
//             opacity: 0.7,
//             pixelValuesToColorFn: values => values[0] === 42 ? '#ffffff' : '#000000',
//             resolution: 64
//           });

//           setGeoRasterLayer(layer);
//         });
//       });
//   }, []);

//   return geoRasterLayer ? <GeoRasterLayer layer={geoRasterLayer} /> : null;
// };


const MapLeaflet = ({ latitude, longitude, setLatitude, setLongitude, zoom, polygonLayer, oilLayer, pngLayer,
                    polygonDatas, setPolygonDatas}) => {
    const center = [Number(latitude), Number(longitude)]
    const [bounds, setBounds] = useState([]);
    const mapRef = useRef(null);
    const url = 'output.tif'
    const [geoRasterLayer, setGeoRasterLayer] = useState(null);
    const [popupPosition, setPopupPosition] = useState(null);

    useEffect(() => {
      const urlToGeotiffFile = "output.tif";
  
      fetch(urlToGeotiffFile)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          parseGeoRaster(arrayBuffer).then(georaster => {
            console.log("georaster:", georaster);
  
            const layer = new GeoRasterLayer({
              georaster: georaster,
              opacity: 0.7,
              pixelValuesToColorFn: values => values[0] === 42 ? '#ffffff' : '#000000',
              resolution: 64
            });

            const bound = [[georaster.xmax, georaster.ymax], [georaster.xmin, georaster.ymin]]
            setBounds(bound)
            
  
            //setGeoRasterLayer(layer);
          });
        });
    }, []);
    
    const handleMapClick = (e) => {
      setPopupPosition(e.latlng);
      console.log(e.latlng)
    };

    const polygonPaths = [
        [-12.9508,-38.5014],
        [-12.9634, -38.5014 ],
        [-12.9634-38.4747],
        [-12.9508,-38.4747 ],
    ];
    const rectanglePaths = [
        [-12.9508,-38.5014],
        [-12.9634, -38.4747],
    ];

    const imageBounds = [
        [-12.948,-38.499],
        [-12.953, -38.505],
    ]

   const tifBounds = [
    [-118.0701947191598, 33.668053631002124],
    [-118.11340803471172, 33.544121213124185]    
   ]


   const testeBounds =[
    [0.004491576419841916, 0.0031441034928338944],
    [0.000898315284119522, 0.0004491576410420987]
   ]

   //const centerTeste = [0.004491576419841916, 0.0031441034928338944]
   //const centerTeste = [-118.0701947191598, 33.668053631002124]

   const handlePolygonClick = (event) => {
    console.log('Latitude:', event.latlng.lat);
    console.log('Longitude:', event.latlng.lng);
    setPolygonDatas(true)
   }

    return(
        <>
            <MapContainer center={center} zoom={12} scrollWheelZoom={true} style={{height: '100vh'}} 
              whenCreated={mapInstance => { mapRef.current = mapInstance; }}
              onClick={handleMapClick}
            >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <GeoRasterOverlay/> */}
            
            { oilLayer && <Circle center={center} fillColor='red' color='red' radius='500'
              eventHandlers={{click: handlePolygonClick}}
              />}

            { polygonLayer && <Rectangle bounds={rectanglePaths} eventHandlers={{click: handlePolygonClick}}/>}
            {polygonDatas && <ImageOverlay 
              url='example_4326.png'
              bounds={rectanglePaths}
              />}
            { pngLayer && <ImageOverlay 
                url='example_4326.png'
                bounds={imageBounds}
            />}
            <ImageOverlay 
            url='example_4326.png'
            bounds={tifBounds}
            />
            
            <PositionPopUp/>

            {/* <ImageOverlay url='example_4326.png' bounds={imageBounds}/> */}
            {/* {popupPosition && (
        
            {/* <FitBounds /> */}
            {/* {bounds && <ImageOverlay url={url} bounds={bounds} />} */}
    
            
            
        </MapContainer>
        </>
    )
}

export default MapLeaflet