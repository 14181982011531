import React, {useState} from "react";
import DatePicker from "react-datepicker";
import { subDays, addDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import '../sidebar/style.css';

const Sidebar = ({ longitude, latitude, setLongitude, setLatitude, zoom, setZoom, oilLayer, setOilLayer, 
    polygonLayer,setPolygonLayer, pngLayer, setPngLayer, polygonDatas, setPolygonDatas}) => {
    const [startDate, setStartDate] = useState(new Date());

    return (
        <section className="sidebar_container">
            <div className="sidebar_content">
                <div className="logo">
                    <div className="logo_image">
                        <img src="./logoRioss.png"/> 
                    </div>
                    <h1>RIOSS</h1>
                </div>

                {polygonDatas ? 
                <div className="polygonsDatas">
                    <div className="layerContainer">
                        <button className="backButton" onClick={() => {setPolygonDatas(false)}}>
                            <img src="./leftarrow.svg" alt=""/>
                        </button>
                        <h2 className="h2Styles">Polygon SideInfos</h2>
                    </div>
                    
                </div>
                :
                
                <div className="sidebar_inputs_container">
                    <div className="inputs">
                        <div className="labelInput">
                                <div className="labelImg">
                                    <img src="./point.svg"/>
                                </div>
                                <label htmlFor="date">Data</label>
                            </div>
                        <div className="input">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                includeDateIntervals={[
                                    { start: subDays(new Date(), 7), end: addDays(new Date(), 0) },
                                ]}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </div>
                        <div className="input">
                            <div className="labelInput">
                                <div className="labelImg">
                                    <img src="./point.svg"/>
                                </div>
                                <label htmlFor="lat">Latitude</label>
                            </div>
                            <input id="lat" type="text" value={latitude} onChange={(e) => setLatitude(prev => prev = e.target.value)} placeholder="Latitude"/>
                        </div>
                        <div className="input">
                            <div className="labelInput">
                                <div className="labelImg">
                                    <img src="./point.svg"/>
                                </div>
                                <label htmlFor="lng">Longitude</label>
                            </div>
                            <input type="text" value={longitude} onChange={(e) => setLongitude(prev => prev = e.target.value)} placeholder="Longitude"/>
                        </div>
                        <div className="input">
                            <div className="labelInput">
                                    <div className="labelImg">
                                        <img src="./zoom.svg"/>
                                    </div>
                                <label htmlFor="zoom">Zoom</label>
                            </div>
                            <input type="text" value={zoom} onChange={(e) => setZoom(prev => prev = e.target.value)} placeholder="Zoom"/>
                        </div>
                    </div>

                    <div className="layers">
                        <div className="layerContainer">
                            <div className={ oilLayer ? "disableButtonStyle" : "buttonsStyle"}>
                                <button onClick={() => {setOilLayer(!oilLayer)}}>
                                    <img src={ oilLayer ? "./oil-white.svg": "./oil-blue2.svg"} />
                                </button>
                            </div>
                            <p className={"style"}>Oil Layer</p>
                        </div>
                        <div className="layerContainer">
                            <div className={ polygonLayer ? "disableButtonStyle" : "buttonsStyle"}>
                                <button onClick={() => {setPolygonLayer(!polygonLayer)}}>
                                    <img src={polygonLayer ? "./layer-white.svg" : "./layer-blue.svg"} />
                                </button>
                            </div>
                            <p className={"style"}>Polygon Layer</p>
                        </div>
                        <div className="layerContainer">
                            <div className={ pngLayer ? "disableButtonStyle" : "buttonsStyle"}>
                                <button onClick={() => {setPngLayer(!pngLayer)}}>
                                    <img src={pngLayer ? "./png-white.svg" : "./png-blue.svg"} />
                                </button>
                            </div>
                            <p className={"style"}>Png Layer</p>
                        </div>
                    </div>
                </div> }
            </div>
        </section>
    )
}

export default Sidebar;