import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import {GoogleOAuthProvider} from '@react-oauth/google'
//import { initialize } from '@google/earthengine';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

// initialize({
//   clientId: CLIENT_ID,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
