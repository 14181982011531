import React, { useEffect, useState, useRef } from 'react'
import { MapContainer, TileLayer, Popup, useMapEvents, Marker, Circle, Polygon, Rectangle, ImageOverlay} from 'react-leaflet'


const PositionPopUp = () => {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
      click(e) {
        setPosition(e.latlng)
      },
    })
  
    return position === null ? null : (
      <Popup position={position}>
        Lat: {position.lat} <br/>
        Lng: {position.lng}
      </Popup> 
    )
}

export default PositionPopUp