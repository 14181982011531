import React, { useEffect, useState } from 'react'
import ee from '@google/earthengine'
import {init} from '@google/earthengine'
import {GoogleLogin, GoogleOAuthProvider,CredentialResponse} from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { GoogleMap, useLoadScript, ReactGoogleMapLoader, LoadScript, Polygon } from '@react-google-maps/api'
import './App.css'
import { Loader } from "@googlemaps/js-api-loader"
import Map from './components/map/map'
import Sidebar from './components/sidebar'
import MapLeaflet from './components/map/mapLeaflet'


function App() {
  const [acessToken, setAcessToken] = React.useState('')
  const clientID = process.env.REACT_APP_CLIENT_ID
  const privateKey = process.env.REACT_APP_PRIVATE_KEY
  const apiKeyEarth = 'AIzaSyB1GkT1TM6a8du7dmAuKALwLn87-_3fPAA'
  const [imageUrl, setImageUrl] = useState('');
  const [loadError, setLoadError] = useState(false);
  const [clickButton, setClickButton] = useState(false)
  const [latitude, setLatitude] = useState("-12.9508");
  const [longitude, setLongitude] = useState("-38.5014");
  const [zoom, setZoom] = useState("10");
  const [oilLayer, setOilLayer]= useState(false)
  const [polygonLayer, setPolygonLayer] = useState(false)
  const [pngLayer, setPngLayer] = useState(false)
  const [polygonDatas, setPolygonDatas] = useState(false)

  
  const onSucess = (credentialResponse) => {
    const credential = credentialResponse.credential
    setAcessToken(credential)
    //loadMap(credential)

  }

  const onError = (error) => {
    console.error('Loginfailed:', error)
  }
        
  console.log(pngLayer)
  return (
    <>
      <section className='main_container'>
        <div id='sidebar'>
          <Sidebar setLatitude={setLatitude} setLongitude={setLongitude} latitude={latitude} longitude={longitude} zoom={zoom} setZoom={setZoom} 
          oilLayer={oilLayer} setOilLayer={setOilLayer} polygonLayer={polygonLayer} setPolygonLayer={setPolygonLayer} pngLayer={pngLayer} setPngLayer={setPngLayer}
          polygonDatas={polygonDatas} setPolygonDatas={setPolygonDatas}
        />
        </div>
        <div id='map'>
          <MapLeaflet oilLayer={oilLayer} polygonLayer={polygonLayer}
          latitude={latitude} longitude={longitude} zoom={zoom}
          setLatitude={setLatitude} setLongitude={setLongitude}
          pngLayer={pngLayer} setPngLayer={setPngLayer}
          polygonDatas={polygonDatas} setPolygonDatas={setPolygonDatas}/>
        </div>
        </section>
      {/* <section className='main_container'>
      
      <Sidebar setLatitude={setLatitude} setLongitude={setLongitude} latitude={latitude} longitude={longitude} zoom={zoom} setZoom={setZoom} 
        oilLayer={oilLayer} setOilLayer={setOilLayer} polygonLayer={polygonLayer} setPolygonLayer={setPolygonLayer}
      />
      <Map oilLayer={oilLayer} polygonLayer={polygonLayer}
        latitude={latitude} longitude={longitude} zoom={zoom}
        setLatitude={setLatitude} setLongitude={setLongitude}
      />
      
      </section> */}
    </>
    
  )
   
}
        
export default App;
        
      
    //   const privateKey = process.env.REACT_APP_PRIVATE_KEY

    //   ee.data.authenticateViaOauth(
    //     clientID,
    //     () => {
    //       console.log('Authentication successful.');
    //       ee.initialize(
    //           null, null,
    //           () => {
    //             console.log('Earth Engine client library initialized.');
    //           },
    //           (err) => {
    //             console.log(err);
    //             console.log(
    //                 `Please make sure you have created a service account and have been approved.
    // Visit https://developers.google.com/earth-engine/service_account#how-do-i-create-a-service-account to learn more.`);
    //           });
    //     },
    //     (err) => {
    //       console.log(err);
    //     });



        // Initialize client library and run analysis.
        // var initialize = () => {
        //   ee.initialize(null, null, () => {
        //     // ... run analysis ...
        //   }, (e) => {
        //     console.error('Initialization error: ' + e);
        //   });
        // };

        // Authenticate using an OAuth pop-up.
        // ee.data.authenticateViaOauth(clientID, () => {
        //   console.log("Authentication sucess")
        // }, 
        //   (e) => {
        //     console.error('Authentication error: ' + e)
        //   })
        // ee.data.authenticateViaOauth(null, (e) => {
        //   console.error('Authentication error: ' + e);
        // }, null, function() {
        //   ee.data.authenticateViaPopup(initialize);
        // });

        // const success = () => {

        // }

        // var image = new ee.Image('srtm90_v4');

        // credentialResponse => {
          
        //   //const credential = jwt_decode(credentialResponse.credential)
        //   // const acessToken =credentialResponse.tokenObj

        //   // const image =  new ee.Image('srtm90_v4')

        //   console.log(credentialResponse.credential)



        // const initializeEarthEnginee = async () => {
        //   try {
            
        //   if (acessToken){
        //     console.log('acessToken','chegou aqui')
        //   //   const { ee } = window;
        //   //   await ee.data.authenticateViaOauth(acessToken);
        //   //   await ee.initialize();
        //   //   // Exemplo de chamada à API do Google Earth Engine para adicionar uma camada de imagem
        //   //   const imageId = 'COPERNICUS/S2_SR/20210701T090559_20210701T090607_T36RYR';
        //   //   const image = await ee.Image(imageId).getThumbURL({ dimensions: '512x512' });
    
        //   //   const map = new window.google.maps.Map(document.getElementById('map'), {
        //   //     center: { lat: 0, lng: 0 },
        //   //     zoom: 2,
        //   //   });
    
        //   //   const imageOverlay = new window.google.maps.GroundOverlay(image, {
        //   //     north: 90,
        //   //     south: -90,
        //   //     east: 180,
        //   //     west: -180,
        //   //   });
    
        //   //   imageOverlay.setMap(map);
        //   }
          
          
    
        // } catch (error) {
        //     console.log(error)
        // }}

// !!!!!!!!!!!!!!!!!!!!

// const loadMap = async (credential) => {

    // if(credential) {
    //   const assetId = 'projects/earthengine-public/assets/LANDSAT'

    //   const url = `https://earthengine.googleapis.com/v1alpha/${assetId}?access_token=${credential}`

    //   fetch(url).then((response) => response.json()).then((data) => {
    //     setImageUrl(data.image);
    //     setLoadError(false);
    //   })
    //   .catch((error) => {
    //     console.error('Erro na solicitação:', error);
    //     setLoadError(true);
    //   });
    // } 
  

    // console.log(credential)

    // const script = document.createElement('script');
    // script.src='https://maps.googleapis.com/maps/api/js?key=AIzaSyBNEivCQePQ5XTr-oK0y_mP9JUgAzYA3-8'
    // script.defer = true;
    // script.async = true;

    // const mapOptions = {
    //             center: { lat: 0, lng: 0 },
    //             zoom: 2,
    //           }

    // const map = new window.google.earth.Map(document.getElementById('map'), mapOptions);
        
    //     const mapContainer = document.getElementById('map');
        
        
    //     // Instantiate an image with the Image constructor.
    //     var image = ee.Image('CGIAR/SRTM90_V4');

    //     // Zoom to a location.
    //     map.setCenter(-112.8598, 36.2841, 9); // Center on the Grand Canyon.

    //     // Display the image on the map.
    //     map.addLayer(image);


    // await ee.data.authenticateViaOauth(clientID, () => {
    //   ee.initialize(null, null, () =>{
    //     console.log('Authenticate Sucessfully')

    //     const mapOptions = {
    //           center: { lat: 0, lng: 0 },
    //           zoom: 2,
    //         };
      
    //     const map = new window.google.earth.Map(document.getElementById('map'), mapOptions);
        
    //     // const mapContainer = document.getElementById('map');
    //     // const map = new ee.Map();
        
    //     // // Instantiate an image with the Image constructor.
    //     // var image = ee.Image('CGIAR/SRTM90_V4');

    //     // // Zoom to a location.
    //     // map.setCenter(-112.8598, 36.2841, 9); // Center on the Grand Canyon.

    //     // // Display the image on the map.
    //     // map.addLayer(image);


    //   })
    // },
    // (error) => {
    //   console.log('Authenticate Failed', error)
    // })
    
    
    // const script = document.createElement('script');
    // script.src='https://maps.googleapis.com/maps/api/js?key=SUA_CHAVE_DE_API'
    // script.src = 'https://cloudresourcemanager.googleapis.com/projects/ee-claraoptiu';
    //https://earthengine.googleapis.com/v1alpha/projects/earthengine-public/assets/LANDSAT
    // script.defer = true;
    // script.async = true;

    // window.initialize = async () => {
    //   // Autenticação e inicialização do Google Earth Engine
    //   // ee.Authenticate();
    //   // ee.initialize();
    //   await ee.data.authenticateViaOauth(credential);
    //   await ee.initialize();

    //   const mapOptions = {
    //     center: { lat: 0, lng: 0 },
    //     zoom: 2,
    //   };

    //   const map = new window.google.earth.Map(document.getElementById('map'), mapOptions);
    // };

    // document.body.appendChild(script);

    // return () => {
    //   // Limpar o script ao desmontar o componente, se necessário
    //   document.body.removeChild(script);
    // };
    // if(credential) {
    // console.log('cheogu aqui', credential)

    // await ee.data.authenticateViaOauth(credential);
    // await ee.initialize();
  
    // // const mapContainer = document.getElementById('map');
    // // const map = new ee.Map();
    // // map.setControlVisibility(false);
    // // mapContainer.appendChild(map.getDiv());
    // } else {
    //   console.log('sem acess Token', credential)

    // }
  
  
    // map.centerObject([longitude, latitude], zoomLevel);
  // };

  // useEffect(() => {
  //   <LoadScript googleMapsApiKey='AIzaSyADa5H-ZwKQIt6jnUkLVtB1G0iyQ4hDkeQ'>
  //     <GoogleMap 
  //       mapContainerStyle={{width:'400px', height:'400px'}}
  //       zoom={10} 
  //       center={{lat:44, lng: -80}} 
  //       mapContainerClassName='mapName'>
  //     </GoogleMap>
  //   </LoadScript>
  // }, []);

  // <GoogleOAuthProvider clientId={clientID}>
    //   <div>
    //     {acessToken ?
    //      <Map/> 
    //       // <div className='map'>
    //       // <img src={imageUrl} alt="Earth Engine Map" style={{ maxWidth: '100%', height: 'auto' }} />
    //       // </div>
    //     :
    //   <GoogleLogin 
    //     clientId= {clientID}
    //     onSuccess={onSucess}
    //     onError={onError}
    //     cookiePolicy={'single_host_origin'}
    //   />
    //   }
    //   </div>
    // </GoogleOAuthProvider>

    // !!!!!!!!!!!!!!!!!!!!!
    // export function Map() {
//   // const {mapIsLoaded} = useLoadScript({
//   //   id: 'google-map-script',
//   //   googleMapsApiKey: "AIzaSyADa5H-ZwKQIt6jnUkLVtB1G0iyQ4hDkeQ",
//   // })

//   const polygonPaths = [
//     { lat: -12.9508, lng: -38.5014 },
//     { lat: -12.9634, lng: -38.5014 },
//     { lat: -12.9634, lng: -38.4747 },
//     { lat: -12.9508, lng: -38.4747 },
//   ];

//   return (
//     <LoadScript googleMapsApiKey='AIzaSyBNEivCQePQ5XTr-oK0y_mP9JUgAzYA3-8'>
//       <GoogleMap 
//         mapContainerStyle={{width:'100vw', height:'100vh'}}
//         zoom={10} 
//         center={{lat: -12.9508, lng: -38.5014}} 
//         mapContainerClassName='mapName'>
//         <Polygon
//           path={polygonPaths}
//           options={{
//             fillColor: '#FF0000',
//             fillOpacity: 0.35,
//             strokeColor: '#FF0000',
//             strokeOpacity: 1,
//             strokeWeight: 2,
//           }}
//         />
//       </GoogleMap> 
//     </LoadScript>

    // <div className='map'> { 
    //   <GoogleMap 
    //     mapContainerStyle={{width:'100vw', height:'100vh'}}
    //     zoom={10} 
    //     center={{lat: -12.9508, lng: -38.5014}} 
    //     mapContainerClassName='mapName'>
    //     <Polygon
    //       path={polygonPaths}
    //       options={{
    //         fillColor: '#FF0000',
    //         fillOpacity: 0.35,
    //         strokeColor: '#FF0000',
    //         strokeOpacity: 1,
    //         strokeWeight: 2,
    //       }}
    //     />
    //   </GoogleMap> 
    // }
    // </div> 
//   )
// }